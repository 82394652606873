.menu
	transform translateY(-200%)
	position fixed
	display block
	background-color $blue
	width 100%
	height 100vh
	top 0
	left 0
	z-index 1000
	transition .4s cubic-bezier(.165, .84, .44, 1)
	+below($sm)
		background-color transparent
		height 100%
		overflow-y scroll
	&_open
		background-color transparent
		transform translateY(0)
		+below($sm)
			padding-top 8rem
	&__wrapper
		width 100%
		height 100%
		display flex
		+below($sm)
			flex-direction column
			height auto
	&__main
		width 62.5%
		+below($sm)
			width 100%
		&_search_active
			.menu__category-list
				height 0
				pointer-events none
			.menu__category-item
				opacity 0
			.menu__list
				display none
			.menu__drawer
				height 100%
				overflow-y auto
			.search__back
				display block
			.search__result
				transition .2s
				opacity 0
				display flex
				&_show
					opacity 1
			.menu__search
				margin-top 8rem
	&__search
		margin-top auto
	&__category
		&-list
			height: 30vh
			display flex
			background-color $w
			transition height .3s
			+below($sm)
				height auto
				flex-direction column
		&-item
			width 50%
			display flex
			transition opacity .3s
			opacity 1
			+below($sm)
				width 100%
				border-top 2px solid $g
			&:first-child
				border-right 1px solid $g
			&:last-child
				border-left 1px solid $g
		&-link
			width 100%
			text-decoration none
			display flex
			flex-direction column
			padding 9vh 5vw 9vh 5vw
			+below($sm)
				padding 2.5rem 1.5rem
			&:hover
				.menu__category-title
					color $blue
			&:hover
				.menu__arrow
					stroke $blue
		&-title
			font-size 3.2rem
			line-height 1.25
			font-weight 700
			color $b
			margin-bottom 4rem
			transition .3s
			+below($sm)
				margin-bottom 0
				font-size 2.4rem
	&__arrow
		width 57px
		height 16px
		stroke #e7e7e7
		transition .3s
		+below($sm)
			display none
	&__drawer
		display flex
		flex-direction column
		background-color $blue
		padding 5vh 5vw
		height 70vh
		transition height .3s
		overflow scroll
		+below($sm)
			padding 2.8rem 1.5rem 5rem
			height auto
	&__list
		column-count 2
		margin-bottom 5vh
		&-link
			position relative
			font-size 2rem
			line-height 2.5
			font-weight 700
			color $w
			text-decoration none
			+below($sm)
				line-height 2
			&:before
				display none
				width 21px
				height 16px
				position absolute
				content ''
				background-image url(../images/arrow-menu-list.svg)
				left -44px
				top 50%
				transform translateY(-50%)
			&:hover
				&&:before
					display block
	&__close
		position absolute
		top 52px
		right 55px
		+below($sm)
			padding 10px
			top 15px
			right 5px
		&-icon
			stroke $w
			width 40px
			height 40px
			+below($sm)
				width 20px
				height 20px
				stroke $b
	&__download
		display flex
		flex-wrap wrap
		margin-top auto
		margin-bottom auto
		+below($sm)
			margin-bottom 5vh
			flex-direction column
		+above($sm)
			align-items center
			margin-bottom 2rem
		&-buttons
			display flex
		&-link  
			display flex
			max-width 130px
			height 40px
			margin-right 10px
			img 
				max-width 100%
		&-title 
			font-size 2rem
			line-height 3rem
			color $wo
			+below($sm)	
				margin-bottom 1rem
			+above($sm)
				margin-right 1rem

.search
	+below($sm)
		margin-bottom 4rem
	&__clear
		background-color transparent
		display none
	&__icon
		width 17px
		height 17px
		&_search
			transition .3s
			stroke $wo
		&_close
			stroke $w
	&__link
		margin-bottom 20px
		line-height 1.5
	&__box
		position absolute
		right 0
		top 8px
		&_not-empty
			.search__icon_search
				display none
			.search__clear
				display block
	&__input
		transition .3s
		&::placeholder
			transition .3s
	&__wrapper
		&:hover
			.search__input
				border-color $w
				&::placeholder
					color $w
			.search__icon_search
				stroke $w
	&__result
		margin-top 4.8rem
		display none
		flex-direction column
	&__back
		position relative
		font-size 1.8rem
		line-height 2.78
		color $w
		font-weight 400
		padding-left 26px
		margin-bottom 5.6rem
		display none
		&::before
			position absolute
			content ''
			width 10px
			height 10px
			border-left 2px solid $w
			border-bottom 2px solid $w
			left 0
			top 50%
			transform translateY(-60%) rotate(45deg)
.sub
	flex-grow 1
	padding 9vh 5rem 9vh
	background url(../images/sub-bg.svg)
	background-repeat no-repeat
	background-size cover
	display flex
	flex-direction column
	+below($sm)
		padding 4rem 1.5rem
	&__form
		position relative
		margin 0
		margin-top auto
		transition opacity .2s
		&_success
			.sub__block-static
				opacity 0
			.sub__send-block
				display flex
	&__link
		display inline-block
		position relative
		font-size 2.4rem
		line-height 2.08
		text-decoration none
		font-weight 700
		color $w
		&:after
			position absolute
			content ''
			width 10px
			height 10px
			border-right 2px solid $w
			border-bottom 2px solid $w
			right -41px
			top 19px
			transform rotate(-45deg)
			+below($md)
				content none
		&-container
			margin-bottom 4rem
			+below($sm)
				margin-bottom 4rem
	&__label
		display block
		font-size 1.8rem
		font-weight 400
		line-height 1
		color $blue
	&__title
		font-size 2.4rem
		line-height 2.08
		font-weight 700
		color $w
		margin-bottom 2.4rem
	&__input
		margin-bottom 5.5rem
	&__button-wrapper
		text-align right
	&__send-text
		font-size 1.8rem
		line-height 5rem
		color $w
		font-weight 700
	&__send-block
		display none
		flex-direction column
		position absolute
		width 100%
		height 100%
		left 0
		top 0
		justify-content center
		text-align center
		transition .2s
		transition-delay .2s
	&__block-static
		opacity 1
		transition .2s

