.invest-app-slider   
  padding 16.5rem 2rem
  overflow hidden
  +below($md)
    padding 7rem 1.5rem
  &__list  
    position relative
    max-width 117.5rem
    +above($md)
      margin 0 2rem
      width calc(100% - 4rem)
    +above($xl)
      width 100%
      margin 0 auto
  &__container
    max-width 65rem
    width 100%
    +above($md)
      margin 0 auto
    +above($lg)
      margin-left 14rem
      margin-right 0
  &__title 
    margin-bottom 2.7rem
    font-weight bold
    font-size 4.2rem
    line-height 5rem
    color $b
    +below($sm)
      margin-bottom 2.3rem
      font-size 4rem
      line-height 4.5rem
  &__text  
    font-size 2rem
    line-height 3rem
  .slick-dots
    display flex !important
    justify-content center
    margin-top 10rem
    +below($md)
      margin-top 3rem
    li
      margin 0 1rem
      width 3rem
      height .3rem
      background-color $gl
      text-indent -9999px
      transition all .4s ease-out
      &.slick-active
        background-color $blue
  .slick-arrow
    position absolute
    width 16px
    height 16px
    border-top 3px solid $mg
    border-left 3px solid $mg
    text-indent -9999px
    transition border-color .4s ease-out
    +below($sm)
      border-color $blue
    +above($sm)
      &:hover
        border-color $blue
    +below($md)
      bottom -.5rem
    +above($md)
      top 20%
  .slick-next   
    transform rotate(135deg)
    +below($md)
      right 0
    +above($md)
      right -2.5rem
    +above($xl)
      right -2rem
  .slick-prev
    transform rotate(-45deg)
    +below($md)
      left 0
    +above($md)
      left -2.5rem
    +above($xl)
      left -2rem
  .slick-slide
    transition all .4s ease-out
    +above($md)
      opacity 0
  .slick-active 
    opacity 1