.homepage-text-wrapper
  position relative
  border-top 2px solid #f0f0f0
.homepage-text
  position relative
  margin 100px 0
  padding-left 150px
  line-height 1.5
  font-size 20px
  background-image url(../images/opened-book.svg)
  background-repeat no-repeat
  background-position left top
  background-size 80px
  +below($sm)
    padding-top 150px
    padding-left 0
    margin-top 70px
    background-position center top
    background-size 60px
  p
    margin 0 0 35px
    padding 0
    +below($sm)
      margin 0 0 25px
  h2
    margin 0 0 45px
    padding 0
    font-weight bold
    font-size 32px
    +below($sm)
      margin 0 0 35px
      font-size 24px
  ol
    margin 35px 0 0
    padding 0
    counter-reset list-counter
    li
      position relative
      margin 0 0 35px
      counter-increment list-counter
      +below($sm)
        margin 0 0 25px
        padding-left 40px
      &::before
        content counter(list-counter)
        position absolute
        left -150px
        font-weight bold
        +below($sm)
          left 0
  strong
    font-weight bold
  b
    font-weight bold