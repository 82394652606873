.footer
	display block
	&__top
		padding 9rem 0 0
		background $bgrad
		+below($sm)
			padding 6.5rem 0 0
		&-wrapper
			display flex
			+below($sm)
				flex-direction column
	&__bottom
		padding 4.2rem 0
		background-color #001331
		+below($sm)
			padding 3.5rem 0 2.5rem
		&-wrapper
			display flex
			align-items center
			justify-content space-between
			+below($sm)
				align-items center
				flex-direction column-reverse
	&__contacts
		padding 5rem 0
		margin-top 11rem
		color $textblue
		border-top solid 2px $darkblue
		+below($sm)
			padding 5.7rem 0
			margin-top 0
			background-color #001331
			border-top none
			border-bottom solid 2px $darkblue
		&-wrapper
			display flex
			align-items center
			margin 0 auto
			+below($sm)
				flex-direction column
				align-items start
		&-tel
			margin-right 4rem
			color $textblue
			font-size 28px
			white-space nowrap
			text-decoration none
			+below($sm)
				display block
				margin-bottom 1.5rem
				text-decoration underline
				color $lb
		&-address
			font-size 18px
			font-weight bold
			line-height 1.5
			+below($sm)
				font-size 14px
	&__email
		width 50%
		padding-right 5rem
		+below($lg)
			padding-right 2rem
		+below($sm)
			width 100%
			padding-right 0
	&__list
		width 50%
		column-count 2
		padding-left 7rem
		+below($lg)
			padding-left 2rem
		+below($sm)
			padding-top 3.5rem
			padding-bottom 3.5rem
			width 100%
			padding-left 0
			column-count 1
		&-link
			text-decoration none
			font-size 1.8rem
			line-height 2.22
			font-weight 700
			color $lb
			transition .3s
			&:hover
				color $w
	&__social
		margin 0 -1.5rem
		display flex
		&-link
			display inline-block
	&__icon
		margin 0 1.5rem
		width 20px
		height 20px
		fill $lb
		transition fill .3s
		&:hover
			fill $w
	&__legal
		max-width 430px
		margin-right 2rem
		font-size 1rem
		line-height 1.5rem
		color #829BC3
		+below($sm)
			margin 4.5rem 0 0
			font-size 1.2rem
			line-height 2rem
	&__text
		padding 0
		margin 0 0 15px
.email
	&__title
		font-size 3.2rem
		line-height 1.25
		font-weight 700
		color $w
		margin-bottom 4rem
	&__form
		position relative
		display flex
		flex-direction column
	&__input
		background-color transparent
		border none
		outline none
		font-size 2.4rem
		line-height 1.46
		color $w
		border-bottom 2px solid #829BC4
		margin-bottom 5rem
		transition border .3s
		&::placeholder
			transition .3s
			color #829BC4
		&:focus
		&:hover
			border-bottom 2px solid $w
			&::placeholder
				color $w
	&__button
		display flex
		align-items center
		background-color transparent
		border none
		font-size 2.4rem
		line-height 1.46
		color $w
	&__arrow
		margin-right 2rem
	&__success-window
		position absolute
		width 100%
		height 100%
		top 0
		left 0
		display flex
		justify-content flex-start
	&__success-text
		font-size 2.4rem
		line-height 3.5rem
		font-weight 700
		color $w
