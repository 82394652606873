.advantages
  padding 4.2rem 1.5rem 10.7rem
  &__container
    max-width 58rem
    width 100%
    margin 0 auto
  &__title,
  &__text
    font-size 2rem
    line-height 3.5rem
    color $b
  &__title
    margin-bottom 1.5rem
    font-weight bold
  &__list-item
    margin-bottom 6rem
    &:last-child 
      margin-bottom 0
