.download-app-small
  &__container
    display flex
    width 100%
    max-width 126rem
    +below($sm)
      flex-direction column
      padding 0 1.5rem
      margin 0 auto 1rem
    +above($sm)
      height 32rem
      padding 0 1rem
      margin 0 auto 1.5rem
  &__title 
    font-weight 600
    font-size 2.8rem
    line-height 4rem
    margin-bottom 3.2rem
    +below($sm)
      font-size 2.8rem
      line-height 3.5rem
      margin-bottom 3rem
  &__item 
    display flex
    flex-direction column
    justify-content center
    flex 1
    padding 0 2rem
    background-color $gl
    background-repeat no-repeat
    background-position right
    +above($sm)
      align-items center
    &:first-child 
      +below($sm)
        border-bottom 2px solid $w
      +above($sm)
        border-right 2px solid $w
      background-image url('../images/download-app-small/item-1-bg.png')
      .download-app-small__title
        margin-bottom 2.2rem
    &:last-child
      // +above($sm)
      background-image url('../images/download-app-small/item-2-bg.png')
    +below($sm)
      padding 6rem 3.5rem
  &__web-link
    display flex
    justify-content space-between
    align-items center
    width 100%
    max-width 43rem
    padding .7rem 3.5rem .3rem
    font-weight 600
    font-size 2rem 
    line-height 5rem
    color $blue
    border 2px solid $blue
    border-radius .7rem
    text-decoration none
  &__store
    display flex
    flex-wrap wrap
  &__link 
    display flex
    height 6rem
    margin-top 1rem
    &:first-child
      margin-right 1rem
  &__icon 
    width 3.5rem
    height 1.6rem