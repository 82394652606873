.pagehead
	position relative
	display block
	background-color $blue
	max-height 412px
	overflow hidden
	@keyframes zoomIn
		0% 
			transform scale(1)
			opacity 0
		10%
			transform scale(1.1)
			opacity 1
		80%
			transform scale(1.8)
			opacity 1
		100%
			transform scale(2)
			opacity 0
	&__bg
		display block
		margin-left auto
		max-width 128rem
		height 412px
		animation 10s infinite forwards linear zoomIn
		+below($sm)
			height 257px
	&__image-box
		position absolute
		top 7rem
		left 50%
		transform translateX(-50%)
		+below($sm)
			top 5rem
			max-width 120px
			img
				width 100%
				object-fit scale-down
	&_fullscreen-img
		.pagehead__image-box
			left 0
			top 0
			transform none
			width 100%
			height 100%
			+below($sm)
				max-width 100%
			img
				display block
				width 100%
				height 100%
				object-fit cover
