.homepage-questions
  position relative
  margin 100px 0
  +below($sm)
    margin 70px 0
  &__title
		position relative
    margin-bottom 80px
    font-size 32px
    color #000
    font-weight bold
    +below($sm)
      margin-bottom 10px
      font-size 40px
  &__link
    display block
    position relative
    width 58px
    margin 50px auto 0
    font-size 28px
    font-weight bold
    color $blue
    line-height 1.25
    text-align center
    text-decoration none
    border none
    outline none
    &::before
      content ''
      position absolute
      width 100%
      height 3px
      background-color #005ef4
      bottom 0
      left 0
    +below($sm)
      margin-top 20px
      font-size 24px