.depositary-documents 
  margin-bottom 10rem
  +below($md) 
    margin-bottom 6rem
  &__head
    max-width 88rem
    width 100%
    padding 16.5rem 1.5rem 4.9rem
    margin 0 auto
    border-bottom 2px solid $g
    +below($md)
      padding 8rem 0 3rem
    &-container
      max-width 61rem
      width 100%
      margin 0 auto
    &-title 
      font-weight bold
      font-size 3.2rem
      line-height 4rem
      +below($md)
        font-size 2.8rem
        line-height 3.5rem
    &-image 
      margin-left -4rem
      margin-bottom 9.2rem
      +below($md)
        margin-left 0
        margin-bottom 4rem
        width 12rem
  &__question
    position relative
    padding 0 15rem
    font-weight bold
    font-size 2.8rem
    line-height 3.5rem
    color $b
    transition color .3s
    cursor pointer
    +below($sm)
      font-size 2.4rem
    +below($md)
      padding 0 4rem
      font-weight normal
    &:before   
    &:after   
      position absolute
      content ''
      width 24px
      height 4px
      background-color $blue
      right 50px
      top 16px
      +below($md)
        right 0
        top 15px
    &:before 
      transition .3s
      transform rotate(90deg)
    &:hover
      color $blue
  &__list 
    max-width 88rem
    width 100%
    margin 0 auto
    counter-reset documents
    &-inside
      padding 3.8rem 15rem
      +below($md)  
        padding 3.8rem 0
      .download
        margin-bottom 4.7rem
      &_entrepreneur
        counter-reset entrepreneur
        .depositary-documents__item-inside
          &:before   
            counter-increment entrepreneur
            content counter(entrepreneur)
      &_entrepreneurNon
        counter-reset entrepreneurNon
        .depositary-documents__item-inside
          &:before   
            counter-increment entrepreneurNon
            content counter(entrepreneurNon)
      &_IE
        counter-reset IE
        .depositary-documents__item-inside
          &:before   
            counter-increment IE
            content counter(IE)
      &_individual
        counter-reset individual
        .depositary-documents__item-inside
          &:before   
            counter-increment individual
            content counter(individual)
      &_algorithm
        counter-reset algorithm
        .depositary-documents__item-inside
          &:before   
            counter-increment algorithm
            content counter(algorithm)
  &__item-inside 
    position relative
    margin-bottom 3rem
    padding-left 3rem
    font-size 2rem
    line-height 3.5rem
    &:before
      position absolute
      left 0
    &:last-child 
      margin-bottom 0
    a 
      font-weight bold
      text-decoration none
      color $blue
    b 
      font-weight bold
  &__item 
    position relative
    padding 5.4rem 0 4.8rem
    border-bottom 2px solid $g
    +below($md)
      padding 4rem 0
    &:before 
      position absolute
      top 5.4rem
      left 0
      counter-increment documents
      content counter(documents)
      font-size 2.8rem
      line-height 1.25
      transition .3s
      +below($md)
        top 4rem
    &_open 
      .depositary-documents__question
        color $blue 
        &:before 
          transform rotate(0)
      .depositary-documents__answer
        display block
      &:before 
        color $blue
  &__answer
    display none
  &__remark 
    max-width 58rem
    width 100%
    margin 3.5rem auto 8.8rem
    font-size 2rem
    line-height 3.5rem
    a 
      font-weight bold
      text-decoration none
      color $blue
  &__remarkNon 
    position relative
    max-width 58rem
    width 100%
    margin .9rem auto 0
    padding-left 3rem
    font-size 1.4rem   
    line-height 2.5rem
    color $gg
    &:before 
      position absolute
      content '*'
      left 0
      color $blue
  &__remarkIE
    max-width 58rem
    width 100%
    margin 0 auto
    font-size 2rem
    line-height 3.5rem
  &__download
    &-container
      display flex
      flex-wrap wrap
      margin-bottom 4.8rem
      margin-top 4rem
    &-item
      width calc(100% / 2)
      +below($sm)
        width 100%
        &:first-child
          margin-bottom .1rem
      a
        position relative
        display flex
        flex-direction column
        border 1px solid $g
        color $b
        text-decoration none
        padding 5rem
        padding-bottom 3rem
        height 28rem
        transition .3s
        +above($sm)
          &:hover
            color $blue
            border-color $blue
            .depositary-documents__download-hover
              opacity 1
        +below($sm)
          border-color $blue
    &-icon
      flex-grow 0
      flex-shrink 0
      width 33px
      height 40px
      +below($sm)
        margin-right 2.7rem
    &-title
      padding-top 2.5rem
      font-size 2rem
      line-height 1.75
      font-weight 700
    &-hover
      position absolute
      flex-grow 0
      flex-shrink 0
      width 26px
      height 37px
      right 5rem
      bottom 3rem
      opacity 0
      transition .3s
      +below($sm)
        opacity 1
  &__algorithm
    max-width 58rem
    width 100%
    margin 7.8rem auto 5rem
    font-weight bold
    font-size 2rem
    line-height 3.5rem

