// Clear the float on any element.
clearfix()
	&:after
		display table
		clear both
		content ''

/// media
below($width)
	@media screen and (max-width: $width - 1)
		{block}

above($width)
	@media screen and (min-width: $width)
		{block}

between($minWidth, $maxWidth)
	@media screen and (min-width: $minWidth) and (max-width: $maxWidth - 1)
		{block}

// Fix no working `-webkit-text-size-adjust: 100%;` in Android.
fix-text-size-adjust()
	background-image linear-gradient(transparent, transparent)

// Set element size
size($width, $height = $width)
	width $width
	height $height

// Set margin on left & right
margin-x($margin)
	margin-left $margin
	margin-right $margin

// Set margin on top & bottom
margin-y($margin)
	margin-top $margin
	margin-bottom $margin

// Set pading on left & right
padding-x($padding)
	padding-left $padding
	padding-right $padding

// Set padding on top & bottom
padding-y($padding)
	padding-top $padding
	padding-bottom $padding

// Set one static retina image
retinaBackground($image, $ext = 'png') {
	background-image url('../images/' + $image + '.' + $ext)

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image url('../images/' + $image + '@2x.' + $ext)
	}
}
// Fonts mixin
font-url(file)
	return '../fonts/' + file

webfont(family, file, hack-chrome-windows = false, weight = 'normal')
	@font-face
		font-family family
		src url(font-url(file + '.eot'))
		src url(font-url(file + '.eot?#iefix')) format('embedded-opentype'),
			url(font-url(file + '.woff')) format('woff'),
			url(font-url(file + '.ttf')) format('truetype'),
			url(font-url(file + '.svg#'+ family)) format('svg')
		font-weight weight
		font-style normal
		font-display swap
	if hack-chrome-windows
		@media screen and (-webkit-min-device-pixel-ratio:0)
			@font-face
				font-family family
				src url(font-url(file + '.svg#'+ family)) format('svg')
