.header
	position fixed
	display block
	padding-top 4.5rem
	padding-bottom 2.5rem
	width 100%
	z-index 40
	background-color transparent
	top 0
	transition backgroud-color .3s
	+below($sm)
		padding-top 1.7rem
	&__wrapper
		display flex
		justify-content space-between
		align-items center
	&__image
		+below($sm)
			width 55px
	&__logo
		position relative
		font-size 12px
		a
			display flex
			align-items center
			color #000
		&-text
			position relative
			width 100px
			margin 0 0 0 20px
			padding 0
			color #979797
			font-weight normal
			+below($sm)
				margin 0 0 0 10px
				line-height 10px
				font-size 10px
	&__link
		text-decoration none
	&__mobile-shape
		display none
		width 2px
		height 32px
		background-color $gl
		+below($sm)
			display block
	&__mobile-link
		display none
		margin 0 20px 0 auto
		text-decoration none
		+below($sm)
			display block
		svg
			width 16px
			height 18px
			stroke #055EF4
	&__menu
		background transparent
		position relative
		font-size 2.4rem
		line-height 1
		font-weight 700
		color $blue
		text-transform capitalize
		+below($sm)
			margin-left 20px
		&:before
			position absolute
			content ''
			width 100%
			height 4px
			background-color $blue
			left 0
			bottom 0
			opacity 0
			transition .3s
		&:hover
			&:before
				opacity 1
	&__category
		display flex
		margin 0 4rem 0 auto
		+below($sm)
			display none
	&__item
		display flex
		align-items center
		margin-left 4rem
	&__shape
		width 2px
		height 32px
		background-color $gl
	&__href
		display flex
		position relative
		color $b
		text-decoration none
		font-size 2.4rem
		line-height 1
		font-weight 700
		transition .3s
		text-transform capitalize
		&:hover
			color $blue
			.header__icon
				stroke $blue
	&__icon
		width 20px
		height 20px
		margin-right 1.6rem
		stroke $b
		transition .3s
