.slider
	display block
	margin-bottom 65px
	&_investor
		margin-bottom -2px
		.unset-padding
			+below($sm)
				padding-left 1.5rem
				padding-right 1.5rem
	&_issuer
		margin-bottom 0
		.unset-padding
			padding-top 0
			+below($sm)
				padding-left 1.5rem
				padding-right 1.5rem
	&__list
		display none
		&.slick-initialized
			display block
		&_reverse
			text-align left
			.slider__logo
				left 4rem
				+below($md)
					left 2rem
			.slider__value
				justify-content flex-start
				flex-direction row-reverse
		&_left
			margin-bottom 1rem
	&__icon
		max-width 100%
	&__wrapper
		background-color #F1F3F6
		padding 5rem 0
	&__item
		position relative
		padding 3rem 4rem
		border-left-width 1px
		border-right-width 1px
		border-bottom 0
		border-radius 20px
		background-color #fff
		margin 0 5px
		a
			text-decoration none
			color #000

		+below($md)
			padding 2rem
	&__value
		display flex
	&__logo
		position absolute
		margin-bottom 1.5rem
		img
			max-height 60px
			max-width 60px
			object-fit contain
	&__provision
		font-size 1.6rem
		line-height 3.5rem
		font-weight 400
		white-space nowrap
		text-overflow ellipsis
		overflow hidden
	&__issue
		color #9C9C9C
		font-size 1.6rem
		line-height 2rem
		font-weight 400
		white-space nowrap
		text-overflow ellipsis
		overflow hidden
	&__percent
	&__cost
		font-size 2.8rem
		line-height 3.5rem
		margin-bottom .9rem
		font-weight 700
	&__percent
		padding-right 2rem
	&__cost
		padding 0 2rem
		border-left 1px solid $dg
	&__name
		padding-top 8px
		padding-left 80px
		font-size 1.4rem
		line-height 2rem
		font-weight 400
		overflow hidden
		text-overflow ellipsis
		white-space nowrap
	&__date
		padding-left 80px
		font-size 1.4rem
		line-height 2rem
		font-weight 300
		color #9C9C9C
		margin-bottom 5rem
		overflow hidden
		text-overflow ellipsis
		white-space nowrap
