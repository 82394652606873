.questions
	display block
	&__list
		position relative
		counter-reset questions
	&__item
		border-top 2px solid $g
		padding 6.4rem 0 6rem
		&:first-child
			border-top none
		+below($sm)
			padding 4.4rem 0 4rem
		&:before
			position absolute
			left 0
			counter-increment questions
			content counter(questions)
			font-size 2.8rem
			line-height 1.25
			transition .3s
			+below($sm)
				font-size 2.4rem
		&_open
			.questions__title
				color $blue
				&:before
					transform rotate(0)
			.questions__text
				display block
			&:before
				color $blue
	&__title
		cursor pointer
		position relative
		padding 0 18rem
		font-size 2.8rem
		line-height 1.25
		transition color .3s
		+below($sm)
			padding 0 4rem
			font-size 2.4rem
		&:before
		&:after
			position absolute
			content ''
			width 24px
			height 4px
			background-color $blue
			right 50px
			top 24px
			+below($sm)
				right 0
				top 15px
		&:before
			transition .3s
			transform rotate(90deg)
		&:hover
			color $blue
	&__text
		font-size 2rem
		line-height 1.75
		padding 0 18rem
		overflow hidden
		transition .4s
		padding-top 5.2rem
		display none
		+below($sm)
			padding 0 4rem
			padding-top 3.6rem
			font-size 1.8rem
			line-height 1.67

