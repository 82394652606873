.news
	display block
	padding-bottom 8rem
	+below($sm)
		margin-bottom 7rem
	&-with-padding
		margin-top 10rem
	&__title
		margin-bottom 1rem
	h2.news__title
		font-size 3.2rem
		line-height 1.25
		font-weight 700
		margin-bottom 2rem
	&__link
		position relative
		display flex
		align-items center
		padding 2rem 0
		transition .3s
		a
			text-decoration none
		&:hover
			.news__image-block
				box-shadow 0 18px 30px 10px rgba(0, 0, 0, .15)
				opacity 1
			.news__arrow
				stroke $blue
			.news__image
				transform scale(1.14)
		+below($sm)
			flex-direction column-reverse
			padding 2rem 0 4.1rem
	&__image
		width 100%
		height 100%
		object-fit cover
		transition .3s
		&-block
			transition .3s
			position relative
			width 280px
			height 280px
			left -140px
			opacity .5
			+below($sm)
				width 100%
				height auto
				left 0
				margin-bottom 3.5rem
	&__text
		display flex
		flex-direction column
		position relative
		width 53rem
		max-width 53rem
		z-index 2
		+below($sm)
			width auto
			margin 0
		&-title
			font-size 2.8rem
			line-height 1.25
			font-weight 400
			color $b
			+below($sm)
				font-size 2.4rem
	&__date
		margin-bottom 1.2rem
		font-weight 300
		font-size 1.6rem
		line-height 2.4rem
		color rgba(34, 34, 34, 0.4)
	&__arrow
		position absolute
		width 57px
		height 16px
		stroke $dg
		right 0
		top 50%
		transform translateY(-50%)
		transition stroke .3s
		+below($sm)
			display none
	&__wrapper
		margin-bottom 5rem
	&__more
		position relative
		border none
		outline none
		font-size 2.8rem
		font-weight 700
		line-height 1.25
		text-align center
		text-decoration none
		color $blue
		+below($sm)
			font-size 2.4rem
			line-height 1.46
		&:before
			content ''
			position absolute
			width 100%
			height 3px
			background-color $blue
			bottom 0
			left 0
	&__button-wrapper
		display flex
		justify-content center
	&_item-page
		margin-top 10rem
		+below($sm)
			margin-top 6rem
			padding-bottom 0

