.category
	margin-top 3.4rem
	display block
	margin-bottom 11rem
	+below($xl)
		margin-bottom 10rem
	+below($sm)
		margin 3.4rem .5rem 6.4rem
	&__wrapper
		position relative
		display flex
		+below($sm)
			flex-direction column
			background-color $blue
			border-radius 15px
	&__image-block
		top 0
		position relative
		transition transform 1s
		opacity 1
		+below($sm)
			display inline-block
			height 100px
			margin-bottom 3rem
			transition none
		img
			+below($sm)
				height 100%
	&__item
		position relative
		width 50%
		padding 186px 85px 130px
		height 570px
		transition .4s
		z-index 6
		text-decoration none
		+below($xl)
			padding 186px 45px 130px
		+below($sm)
			width 100%
			padding 5rem 1.1rem 4.2rem
			height auto
			&:first-child
				border-bottom 2px solid #8DB9FF
		&:hover
		&_hover
			.category__text
				transform translateY(-40px)
			.category__title
				color $w
			.category__text-value
				opacity .5
			.category__image-block
				opacity 1
				z-index 5
				transform translateY(-220px)
			.category__video
				opacity 1
			.category__image
				opacity 0
	&__text
		max-width 350px
		position absolute
		top 289px
		left 150px
		z-index 3
		transition transform .8s
		+below($xl)
			left 45px
		+below($sm)
			position relative
			top 0
			left 0
			text-decoration none
			transition none
		&-value
			font-size 2.8rem
			line-height 1.25
			color $w
			opacity 0
			transition .8s
			+below($sm)
				opacity 1
				font-size 1.8rem
				line-height 1.39
				transition none
	&__title
		color $b
		margin-bottom 1.5rem
		text-decoration none
		+below($sm)
			color $w
			font-size 2.8rem
			line-height 1.25
			margin-bottom .8rem
	&__hover-el
		position absolute
		width calc(50% - 50px)
		height 570px
		border-radius 36px
		background-color $blue
		z-index 2
		transition 1s
		+below($sm)
			display none
		&_move
			transform translateX(100%)
	&__arrow
		position absolute
		width 320px
		height 56px
		left -58px
		bottom 56px
	&__video
		position absolute
		width 200px
		left -1px
		bottom 0
		background transparent
		opacity 0
		transition opacity .2s
		+below($sm)
			display none
	&__image
		transition opacity .1s
