.news-table 
  width 84.2rem
  margin-left calc((100% - 84.2rem)/2)
  +below($lg)
    width 95vw
    margin-left calc((100% - 95vw)/2)
  +below($sm)
    width 100%
    overflow-y auto
  table
    max-width 84.2rem
    width 100%
    +below($lg)
      max-width 95vw
  tr
    display grid
    grid-template-columns 11.3rem 1fr 1fr 1.5fr 1.5fr
    border-bottom 1px solid rgba(0, 94, 244, 0.2)
    +below($md)
      grid-template-columns 6.5rem 1fr 1.5fr 1.5fr 1.5fr
  thead
    font-weight bold
    td, th
      line-height 3rem
      text-align center
  td, th
    padding 1.5rem 1.5rem 1.5rem 2rem 
    font-size 1.6rem
    line-height 2.2rem
    i
      display inline-block
      margin-left 0.5rem
      color $gr
      font-style normal