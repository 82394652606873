.properties
  padding 11.5rem 1.5rem
  +below($sm)
    padding 6rem 1.5rem
  &__info
    width 100%
    +above($md)
      max-width 58rem
      margin-left 4rem
  &__title
    margin-bottom 1.5rem
    font-weight bold
    font-size 3.2rem
    line-height 4rem
    color $b
    +below($sm)
      margin-bottom 2.5rem
  &__text
    font-size 2rem
    line-height 3.5rem
    color $b
    +below($sm)
      font-size 1.8rem
      line-height 3rem
  &__list-item
    display flex
    margin-bottom 12rem
    &:nth-child(1)
      +below($sm)
        img
          width 15.5rem
    &:nth-child(2)
      +below($sm)
        img
          width 12.5rem
    &:nth-child(3)
      +below($sm)
        img
          width 10.3rem
    &:last-child
      margin-bottom 0
    +below($sm)
      padding-bottom 4rem
      margin-bottom 5rem
      border-bottom 2px solid $g
      &:last-child
        padding-bottom 0
        border-bottom none
    +below($md)
      flex-direction column
    +above($md)
      justify-content space-between
      align-items center
  &__list 
    max-width 93rem
    width 100%
    margin 0 auto
  &__image
    display flex
    justify-content center
    max-width 26.2rem
    width 100%
    +below($sm)
      justify-content flex-start 
    +below($md)
      margin-bottom 4rem