.depositary-services
  padding 0 1.5rem
  &__container
    max-width 88rem
    width 100%
    padding 9.5rem 2rem 7.5rem
    margin 0 auto
    border-bottom 2px solid $g
    +below($md)
      padding 7.2rem 0 6.3rem
  &__title
    margin-bottom 1.5rem
    font-weight bold
    font-size 2rem
    line-height 3.5rem
    color $b
  &__info   
    max-width 58rem
    width 100%
    margin 0 auto
  &__item-short 
    font-size 2rem
    line-height 3.5rem
    &:before
      content '•'
      margin-right 2.3rem
  &__question
    position relative
    padding 0 15rem
    font-weight bold
    font-size 2.8rem
    line-height 3.5rem
    color $b
    transition color .3s
    cursor pointer
    +below($sm)
      font-size 2.4rem
    +below($md)
      padding 0 4rem
      font-weight normal
    &:before   
    &:after   
      position absolute
      content ''
      width 24px
      height 4px
      background-color $blue
      right 50px
      top 24px
      +below($md)
        right 0
        top 15px
    &:before 
      transition .3s
      transform rotate(90deg)
    &:hover
      color $blue
  &__list 
    max-width 88rem
    width 100%
    margin 0 auto
    &-inside
      display none
      counter-reset serv
      padding 3.8rem 15rem
      +below($md)
        padding 3.8rem 0
    &_services
        margin-bottom 8.6rem
        counter-reset services
        +below($md)
          margin-bottom 6rem
  &__item-inside 
    position relative
    margin-bottom 3rem
    padding-left 3rem
    font-size 2rem
    line-height 3.5rem
    &:before
      position absolute
      left 0
      counter-increment serv
      content counter(serv)
    &:last-child 
      margin-bottom 0
    b 
      font-weight bold
  &__item 
    position relative
    padding 5.4rem 0 4.8rem
    border-bottom 2px solid $g
    +below($md)
      padding 4rem 0
    &:before 
      position absolute
      top 5.4rem
      left 0
      counter-increment services
      content counter(services)
      font-size 2.8rem
      line-height 1.25
      transition .3s
      +below($md)
        top 4rem
    &_open 
      .depositary-services__question
        color $blue 
        &:before 
          transform rotate(0)
      .depositary-services__list-inside
        display block
      &:before 
        color $blue


    