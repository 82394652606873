.promo
	display block
	position relative
	+below($sm)
		height 490px
	&_faq
		+below($sm)
			overflow hidden
		.promo__title
			position absolute
			text-align left
			max-width 45rem
			bottom 4rem
	&_partners
	&_career
	&_reviews
	&_tariffs
		border-bottom 2px solid $g
	&_relative
		overflow hidden
		position relative
		top -120px
		margin-bottom -120px
		.promo__text-block
			position absolute
			bottom 10.4rem
	&_support
	&_documents
		.promo__text-block
			bottom 7rem
	&__background
			display block
			margin 0 0 0 auto
			width 970px
			height 530px
	&__background_blue
		.background__elements
			fill $blue
			+below($sm)
				transform scale(.6)
	&__background_grey
		.background__elements
			fill #f8f8f8
			+below($sm)
				transform scale(.6)
	&__background_center
		margin 0 auto 3rem
		width 596px
		height 405px
		+below($sm)
			position relative
			right -30%
	&__text
		max-width 50rem
		padding-top 2.1rem
		font-size 2.8rem
		line-height 1.25
		+below($sm)
			padding-top 1.3em
	&__breadcrumbs
		margin-top 1rem
		color $gg
	&__breadcrumb
		padding 1rem 0
		color $blue
		text-decoration none
		cursor pointer
	&__input
		border none
		outline none
		color $b
		border-bottom 2px solid $b
		background transparent
		font-size 2.4rem
		line-height 1.46
		margin-top 8 rem
		padding-bottom 1.2rem
		width 100%
		transition .2s
	.container
		position relative
