.depositary-head 
  height 51.1rem
  padding 0 1.5rem
  background-image url(../images/depositary/head-bg.png)  
  background-repeat no-repeat
  background-position right
  border-bottom 2px solid $g
  +below($md)
    height 25rem
    background-image url(../images/depositary/head-bg-m.png)
  &__container
    display flex
    align-items flex-end
    max-width 87rem
    width 100%
    height 100%
    margin 0 auto
    padding-bottom 10.7rem
    +below($md)
      padding-bottom 6.8rem
  &__title
    font-weight bold
    font-size 4.2rem
    line-height 5rem
    color $b
    +below($sm)
      font-size 4rem   
      line-height 4.5rem