.invest-app
  padding 0 1rem
  &__container 
    display flex
    flex-direction column
    align-items center
    padding 6rem 1.5rem
    margin 0 auto
    background-color $blue
    background-image url(../images/invest-app/computer-bg.png)
    background-repeat no-repeat
    background-position center
    background-size 90%
  &__link
    display flex
    justify-content space-between
    align-items center
    width 100%
    max-width 43rem
    padding .7rem 3.5rem .3rem
    font-weight 600
    font-size 2rem 
    line-height 5rem
    color $w
    border 2px solid $w
    border-radius .7rem
    text-decoration none
    background-color transparent
    svg 
      width 3.5rem
      height 1.6rem
  &__image 
    margin-bottom 3.4rem
    +below($md) 
      max-width 34.5rem
      width 100%
      margin 0 2rem 3.4rem
    +above($md)
      max-width 45%
