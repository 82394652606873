.documents
	display block
	&__list
		display flex
		flex-wrap wrap
	&__item
		width calc(100% / 3)
		+below($sm)
			width 100%
	a
		position relative
		display flex
		flex-direction column
		border 1px solid $g
		color $b
		text-decoration none
		padding 5rem
		padding-bottom 3rem
		height 28rem
		transition .3s
		+below($sm)
			flex-direction row
			height auto
			padding 5rem .5rem
		+above($sm)
			&:hover
				color $blue
				border-color $blue
				.documents__hover
					opacity 1
	&__icon
		flex-grow 0
		flex-shrink 0
		width 33px
		height 40px
		+below($sm)
			margin-right 2.7rem
	&__title
		padding-top 2.5rem
		font-size 2rem
		line-height 1.75
		font-weight 700
		+below($sm)
			padding-top 0
	&__hover
		position absolute
		flex-grow 0
		flex-shrink 0
		width 26px
		height 37px
		right 5rem
		bottom 3rem
		opacity 0
		transition .3s
		+below($sm)
			display none
