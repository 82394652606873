.download-app
  width 100%
  background-color $gl
  background-image url('../images/download-app/background.svg')
  background-repeat no-repeat
  +below($sm)
    background-image url('../images/download-app/background-m.png')
    background-position center
  +above(xl) 
    padding-left 8rem
  &__container
    display flex
    justify-content space-between
    width 100%
    max-width 99rem
    margin 0 auto
    padding 4.7rem 1.5rem 4.2rem
    +below($sm)
      padding 4.5rem 1.5rem 5.5rem
    +below($md) 
      flex-direction column-reverse
  &__download
    display flex
    flex-direction column
    justify-content center
    +below($sm)
      max-width 37rem
      padding 0 2rem
    +below($md)
      max-width 40rem
      margin 0 auto
    +above($md)
      margin-right 4rem
  &__logo 
    width 8rem
    height 8rem
    margin-bottom 1.5rem
    +below($sm)
      width 6rem
      height 6rem
      margin-bottom 2rem
  &__phones
    display flex
    +below($sm)
      max-width 29.5rem
      margin 0 auto 5rem
    +below($md)
      margin 0 auto 5rem 
    +above($md)
      max-width 50%
    +above($lg)
      max-width inherit
    img 
      max-width 225px
      max-height 490px
      border-radius 20px
      box-shadow 0 25px 35px -18px rgba(8, 57, 140, .4)
      +below($sm)
        width calc(50% - .25rem)
      +above($md)
        width calc(50% - .75rem)
      +above($lg)
        width inherit
      &:first-child
        +above($sm)
          margin-right 1.5rem
        +below($sm)
          margin-right .5rem
  &__title 
    font-weight 600
    font-size 2.8rem
    line-height 4rem
    margin-bottom 7.2rem
    +below($sm)
      font-size 2.4rem
      line-height 3.5rem
      margin-bottom 4.3rem
  &__store
    display flex
    flex-wrap wrap
    align-items flex-start
  &__link
    display flex
    &:first-child
      margin-right 1.5rem
      margin-bottom 1.5rem
      +below($sm)
        margin-right .5rem
        margin-bottom .5rem
    img 
      +below($sm)
        height 5rem