.application
  display flex
  align-items center
  justify-content space-between
  max-width 58rem
  width 100%
  padding 3.1rem 4.2rem 3.5rem 6rem
  margin 0 auto
  background-color $blue
  text-decoration none
  &__text   
    font-size 2rem
    line-height 3.5rem
    color $w
